import React from 'react';
import ReactDOM from 'react-dom';

const {
  REACT_APP_WEB_NAME,
  REACT_APP_WEB_BUILD_VERSION,
  REACT_APP_WEB_EMAIL
} = process.env;
ReactDOM.render(
  <>
    <h3>{REACT_APP_WEB_NAME} - {REACT_APP_WEB_BUILD_VERSION}</h3>
    <a className="h5" href={`mailto:${REACT_APP_WEB_EMAIL}`}>{REACT_APP_WEB_EMAIL}</a>
  </>,
  document.getElementById('root')
);
